<template>
  <cui-acl :roles="['TVSettingsAll', 'TVSettingsDep']" redirect>
    <h5>
    <strong>TV Instillinger</strong>
    </h5>
    <a-collapse accordion>
      <a-collapse-panel :key="i" :header="TV.name" v-for="(TV, i) in TVS">
        <h5 class="mb-4">
          <strong>{{ TV.name }}</strong>
        </h5>
        <a-form @submit.prevent="handleSubmit(i)">
          For å kobkle til TV gå til portal.skygruppen.no/#/tv
          <div class="row">
            <div class="col-md-4">
              <a-form-item label="ID">
                <a-input placeholder="ID" v-model="TV.id" :disabled="!TV.newTV"/>
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Navn">
                <a-input placeholder="Navn" v-model="TV.name"/>
              </a-form-item>
            </div>
            <div v-if="!TV.customToken" class="col-md-4">
              <a-form-item label="TV kode">
                <a-input placeholder="XXXX" v-model="TV.code" pattern="\d*" :maxLength="4" style="width: 65%"/>
                <button type="secondary" class="btn btn-light" style="float: right; width: 30%" :loading="loading" @click.prevent="connectTV(i)">Koble</button>
              </a-form-item>
            </div>
            <div v-else class="col-md-4">
              <a-form-item label=".">
                <button type="secondary" :loading="loading" class="btn btn-light px-5 ml-3" @click.prevent="disconnectTV(i)">Koble fra TV</button>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Selskap">
                <a-input placeholder="Selskap" v-model="TV.company" :disabled="!['TVSettingsAll'].some(r => $store.state.user.roles.indexOf(r) >= 0)"/>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Avdeling">
                <a-input placeholder="Avdeling" v-model="TV.department" :disabled="!['TVSettingsAll'].some(r => $store.state.user.roles.indexOf(r) >= 0)"/>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Layout">
                <a-select v-model="TV.layout" :options="layouts"/>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Lengde inhold (i sekunder)">
                <a-input placeholder="20" v-model="TV.length" />
              </a-form-item>
            </div>
          </div>
          <div class="row" v-for="(view, j) in TV.views" :key="j">
            <div class="col-md-12">
              <span>Visning {{ j + 1 }}</span>
              <a-button type="secondary" @click.prevent="deleteView(i, j)" style="float: right">
              <i class="fa fa-trash" ></i>
            </a-button>
            </div>
            <div class="col-md-4">
              <a-form-item label="Type">
                <a-select v-model="view.type" :options="viewTypes"/>
              </a-form-item>
            </div>
            <div class="col-md-4" v-if="view.type === 'iframe'">
              <a-form-item label="Url iframe">
                <a-input v-model="view.url" />
              </a-form-item>
            </div>
            <div class="col-md-4" v-if="view.type === 'tmb2c'">
              <a-form-item label="Avdeling">
                <a-input v-model="view.department" />
              </a-form-item>
            </div>
          </div>
          <div class="col-md-12">
            <button type="primary" class="btn btn-light px-5" @click.prevent="newView(i)">Ny Visning</button>
          </div>
          <div class="col-12 mt-3">
            <a-form-item>
              <button type="primary" class="btn btn-primary px-5" :loading="loading">Lagre</button>
              <button type="secondary" class="btn btn-light px-5 ml-3" @click.prevent="deleteTV(i)">Slett TV</button>
            </a-form-item>
          </div>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
    <button type="secondary" class="btn btn-light px-5" @click="addTV">Opprett ny TV</button>
  </cui-acl>
</template>

<script>
import { firestore, functions } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import cuiAcl from '@/components/cleanui/system/ACL'

const layouts = [
  {
    value: 'portrait',
    label: 'Portrait',
  },
  {
    value: 'landscape',
    label: 'Landscape (med fremhevet nyheter)',
  },
  {
    value: 'landscape2',
    label: 'Landscape 2 (uten fremhevet nyheter)',
  },
]

const viewTypes = [
  {
    value: 'iframe',
    label: 'Embedded Content (iframe)',
  },
  {
    value: 'tmb2c',
    label: 'Talkmore Privat Salesscreen',
  },
]

export default {
  components: {
    cuiAcl,
  },
  data() {
    return {
      TVS: [],
      layouts,
      viewTypes,
      loading: false,
    }
  },
  mounted() {
    let ref = firestore.collection('TV')
    if (['TVSettingsDep'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      ref = ref.where('department', '==', this.$store.state.user.department)
    }
    ref.get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const TV = doc.data()
          TV.uid = doc.id
          TV.newTV = false
          this.TVS.push(TV)
        })
      })
  },
  methods: {
    newView(i) {
      const view = {}
      if (this.TVS[i].views) {
        this.TVS[i].views.push(view)
      } else {
        this.TVS[i].views = [view]
      }
      this.$forceUpdate()
    },
    deleteView(i, j) {
      this.TVS[i].views.splice(j, 1)
      this.$forceUpdate()
    },
    handleSubmit(i) {
      const TV = this.TVS[i]
      console.log(TV)
      let ref = firestore.collection('TV')
      if (this.TVS[i].newTV) {
        ref = ref.add(TV)
      } else {
        ref = ref.doc(TV.uid).set(TV)
      }
      ref.then(() => {
        openNotification('TV instillinger lagret')
        this.$forceUpdate()
      })
    },
    addTV() {
      this.TVS.push({
        id: 'NA',
        name: 'Ny TV',
        newTV: true,
        company: this.$store.state.user.companies[0],
        department: this.$store.state.user.department,
      })
    },
    deleteTV(i) {
      this.loading = true
      const TV = this.TVS[i]
      if (!TV.newTV) {
        firestore.collection('TV').doc(TV.uid).delete()
      }
      this.TVS.splice(i, 1)
      this.loading = false
    },
    connectTV(i) {
      this.loading = true
      const TV = this.TVS[i]
      console.log(TV.uid)
      const call = functions.httpsCallable('authenticateTV')
      call({ code: TV.code, TVid: TV.uid }).then(res => {
        delete this.TVS[i].code
        this.TVS[i].customToken = res.data.customToken
        this.handleSubmit(i)
        openNotification('TV Koblet', 'Ved feil vennligst kontakt IT')
      }).catch(err => {
        openNotification('Feil ved kobling av TV', 'Vennligst prøv igjen eller kontakt IT')
        console.log(err)
      })
      this.loading = false
    },
    disconnectTV(i) {
      this.loading = true
      const TV = this.TVS[i]
      const call = functions.httpsCallable('deleteCustomToken')
      call({ tokenId: TV.uid }).then(res => {
        this.TVS[i].customToken = ''
        this.handleSubmit(i)
        openNotification('TV Frakoblet', 'TV vil fortsatt være aktiv så lenge den ikke prøver å hente ny data')
      }).catch(err => {
        this.TVS[i].customToken = ''
        this.handleSubmit(i)
        openNotification('TV Frakoblet', 'TV vil fortsatt være aktiv så lenge den ikke prøver å hente ny data')
        console.log(err)
      })
      this.loading = false
    },
  },
}
</script>
